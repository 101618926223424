import {Injectable} from '@angular/core';
import {Action, State, StateContext} from '@ngxs/store';
import {patch} from '@ngxs/store/operators';
import jwtDecode, {JwtPayload} from 'jwt-decode';
import {Client} from 'src/store/models/client';
import {
  FetchToken,
  Logout,
  SetExpirationTime,
  UpdateToken,
} from './client-actions';

@State<Client>({
  name: 'client',
  defaults: {
    profile: 't-systems',
    idToken: '',
    jwtExpires: 0,
  },
})
@Injectable()
export class ClientState {
  @Action(FetchToken)
  async fetchToken(
    ctx: StateContext<Client>,
    action: FetchToken,
  ): Promise<void> {
    const token = await action.keycloak.getToken();
    ctx.setState(
      patch<Client>({
        idToken: token,
      }),
    );
  }

  @Action(UpdateToken)
  async updateToken(ctx: StateContext<Client>, action: UpdateToken) {
    ctx.dispatch(new FetchToken(action.keycloak));
  }

  @Action(SetExpirationTime)
  setExpirationTime(ctx: StateContext<Client>, action: SetExpirationTime) {
    const decoded: JwtPayload = jwtDecode(action.jwt);
    if (decoded.exp) {
      const expTime = new Date(1000 * decoded.exp);
      ctx.setState(
        patch<Client>({
          jwtExpires: decoded.exp * 1000,
        }),
      );
    }
  }

  @Action(Logout)
  async logout(ctx: StateContext<Client>, action: Logout) {
    await action.keycloak.logout();

    // clean up
    ctx.setState({
      ...ctx.getState(),
      idToken: '',
    });
  }
}
