import {Injectable} from '@angular/core';
import {Observable, combineLatest, throwError} from 'rxjs';
import {map, switchMap, timeout} from 'rxjs/operators';
import {ContractOffer} from '../../models/contract-offer';
import {Fetched} from '../../models/fetched';
import {MultiFetched} from '../../models/multi-fetched';
import {CatalogApiUrlService} from '../../services/catalog-api-url.service';
import {ContractOfferService} from '../../services/contract-offer.service';
import {NotificationService} from '../../services/notification.service';
import {
  CatalogBrowserPageData,
  ContractOfferRequest,
} from './catalog-browser-page.data';
import {
  AssetService,
  CatalogRequest,
  CatalogService,
} from "src/modules/edc-dmgmt-client";
import { AssetPropertyMapper } from '../../services/asset-property-mapper';

@Injectable({providedIn: 'root'})
export class CatalogBrowserPageService {
  constructor(
    private assetService: AssetService,
    private contractOfferService: ContractOfferService,
    private notificationService: NotificationService,
    private catalogApiUrlService: CatalogApiUrlService,
    private assetPropertyMapper: AssetPropertyMapper,
    private catalogService: CatalogService,
  ) {}

  contractOfferPageData$(
    refresh$: Observable<any>,
    searchText$: Observable<string>,
  ): Observable<CatalogBrowserPageData> {
    return combineLatest([
      refresh$.pipe(switchMap(() => this.fetchCatalogs())),
      searchText$,
    ]).pipe(
      map(([data, searchText]): CatalogBrowserPageData => {
        // Merge fetch results
        const contractOffers = data.requestTotals.data.flat();

        // Apply filter
        const filteredContractOffers = this.filterContractOffers(
          contractOffers,
          searchText,
        );
        
        return {
          requests: data.requests,
          requestTotals: data.requestTotals,
          filteredContractOffers,
          numTotalContractOffers: contractOffers.length,
        };
      }),
    );
  }

  filterContractOffers(
    contractOffers: any,
    searchText: string,
  ): ContractOffer[] {
    if (!contractOffers) {
      return [];
    }
    
    const data: any[] = contractOffers
      .map((contract: any) => {
        const datasets = Array.isArray(contract?.['dcat:dataset'])
          ? contract?.['dcat:dataset']
          : [contract?.['dcat:dataset']];
        
        datasets.forEach((dataset: any) => {
          dataset.endpointUrl = contract['dcat:service']['dct:endpointUrl']
          dataset.participantId = contract['edc:participantId'];
        });
        return datasets
          .map((asset: any) => this.assetPropertyMapper.readProperties(asset))
          .filter((asset: any) => asset.name?.toLowerCase().includes(searchText.toLowerCase()));
      })
      .flat();
    
    return data;
  }

  fetchCatalogs(): Observable<
    Pick<CatalogBrowserPageData, 'requests' | 'requestTotals'>
  > {
    // Prepare to fetch individual Catalogs
    const urls = this.catalogApiUrlService.getAllProviders();
    const sources = urls.map((it) =>{
      const catalogRequest: CatalogRequest =
        {
          "@context": {},
          protocol: "dataspace-protocol-http",
          counterPartyAddress: it,
          querySpec: {
            offset: 0,
            limit: 100
          }
        };
      return this.catalogService.requestCatalog(catalogRequest).pipe(
        timeout({
          first: 5000,
          with: () => throwError(() => new Error(`Timed out after 5s.`)),
        }),
        Fetched.wrap({failureMessage: 'Failed fetching catalog.'}),
      )},
    );

    return combineLatest(sources).pipe(
      map((results: Fetched<any>[]) => MultiFetched.aggregate(results)),
      map(
        (
          requestTotals: MultiFetched<ContractOffer[]>,
        ): Pick<CatalogBrowserPageData, 'requests' | 'requestTotals'> => {
          const presetUrls = this.catalogApiUrlService.getPresetProviders();
          return {
            requestTotals,
            requests: requestTotals.results.map(
              (data, index): ContractOfferRequest => ({
                url: urls[index],
                isPresetUrl: presetUrls.includes(urls[index]),
                data,
              }),
            ),
          };
        },
      ),
    );
  }
}
