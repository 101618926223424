import {Component, NgModule, OnDestroy, OnInit} from '@angular/core';
import {BehaviorSubject, Subject} from 'rxjs';
import {switchMap, takeUntil} from 'rxjs/operators';
import {DashboardData, defaultDashboardData} from './dashboard-data';
import {DashboardDataService} from './dashboard-data.service';
import {AppConfigService} from "src/modules/app/config/app-config.service";
@Component({
  selector: 'edc-demo-dashboard',
  templateUrl: './dashboard.component.html',
})
export class DashboardComponent implements OnInit, OnDestroy {
  data: DashboardData = defaultDashboardData();
  private refresh$ = new BehaviorSubject(true);
  
  dataSpaceNameTitle : string = this.appConfigService?.config?.dataSpaceName
  ? `Connect for ${this.appConfigService?.config?.dataSpaceName}`
  : 'Connect';
  
  documentURL: string = this.appConfigService?.config?.environment === 'production'
    ? 'https://docs.dih.telekom.com/en/home'
    : 'https://dih.telekom.com/en/contact';
  constructor(
     private dashboardDataService: DashboardDataService,
     private appConfigService: AppConfigService,
     ) {}
  ngOnInit() {
    this.refresh$
      .pipe(
        switchMap(() => this.dashboardDataService.getDashboardData()),
        takeUntil(this.ngOnDestroy$),
      )
      .subscribe((data) => {
        this.data = data;
      });
  }

  ngOnDestroy$ = new Subject();

  ngOnDestroy() {
    this.ngOnDestroy$.next(null);
    this.ngOnDestroy$.complete();
  }
  
  protected readonly document = document;
}
