import {APP_INITIALIZER, Provider} from '@angular/core';
import {firstValueFrom} from 'rxjs';
import {KeycloakService} from 'keycloak-angular';
import {AppConfig} from './app-config';
import {AppConfigBuilder} from './app-config.builder';
import {AppConfigFetcher} from './app-config.fetcher';
import {AppConfigService} from './app-config.service';
import {Logout} from "src/store/client/client-actions";
import {Store} from "@ngxs/store";
import jwtDecode from "jwt-decode";

const timeout = async (ms: number) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

const handleAccessDenied = (store: Store, kyService: KeycloakService) => {
  alert('Access Denied: You do not have permission to view this content (Error 403).')
  store.dispatch(new Logout(kyService));
 
}

/**
 * On startup, load app-config.json and build {@link AppConfig}.
 */
export const loadAppConfigOnStartup = (): Provider => ({
  provide: APP_INITIALIZER,
  multi: true,
  deps: [AppConfigFetcher, AppConfigBuilder, AppConfigService, KeycloakService, Store],
  useFactory:
    (
      fetcher: AppConfigFetcher,
      builder: AppConfigBuilder,
      service: AppConfigService,
      kyService: KeycloakService,
      store: Store,
    ) =>
      () => {
        return fetcher.fetchConfigJson().then(async (jsonConfig) =>
        {
          const enableKeycloak = !jsonConfig?.APP_ENABLE_KEYCLOAK || jsonConfig?.APP_ENABLE_KEYCLOAK === 'true'
          if (enableKeycloak) {
            await kyService.init({
              config: {
                url: jsonConfig.APP_KEYCLOAK_URL || '',
                realm: jsonConfig.APP_DEFAULT_KEYCLOAK_REALM || '',
                clientId: jsonConfig.APP_KEYCLOAK_CLIENT_ID || '',
              },
              initOptions: {
                onLoad: 'login-required', // allowed values 'login-required', 'check-sso';
                flow: 'standard', // allowed values 'standard', 'implicit', 'hybrid';
              },
            });
          }
          return jsonConfig;
        }).then(async (jsonConfig) => {
            const enableKeycloak = !jsonConfig?.APP_ENABLE_KEYCLOAK || jsonConfig?.APP_ENABLE_KEYCLOAK === 'true'
            if (enableKeycloak) {
              const token = await kyService.getToken();
              const dihSource: string[] = ['DIH', 'DIH_LILA']
              const source : string[] = ["CATENAX", "COFINITYINT", "COFINITYPROD", "CATENAXINT", "COFINITYTEMP", "COFINITY", "COFINITYPREPROD", "CATENAXLILA"]
              const decodedToken = jwtDecode(token) as { bpn?: string };
              const appSource = jsonConfig?.APP_SOURCE || '';
              const appBpn = jsonConfig?.APP_BPN || '';
              const appRole = jsonConfig?.APP_ROLE || '';
              
              if (
                (!appSource) ||
                (source.includes(appSource) && decodedToken.bpn !== appBpn) ||
                (dihSource.includes(appSource) && !kyService.getUserRoles().includes(appRole)) ||
                (!source.includes(appSource) && !dihSource.includes(appSource))
              ) {
                handleAccessDenied(store, kyService);
              }
              
              const url = jsonConfig['EDC_UI_ORCHESTRATOR_URL'] as string;
              const apiConfig = await firstValueFrom(
                fetcher.fetchConfigFromApi(url, token),
              );
              return {...jsonConfig, ...apiConfig} as Record<string, string | null>;
            }
            else {
              return jsonConfig;
            }
          })
          .then((json) => builder.buildAppConfig(json))
          .then((config: AppConfig) => service.setConfig(config))
      }
});
