/**
 * Asset Property Names
 *
 * SSOT for asset property string names
 */
export const AssetProperties = {
  // edc asset properties
  id: 'edc:id', // needs to start with urn:artifact:
  name: 'edc:name',
  contentType: 'edc:contenttype',
  originator: 'edc:originator',
  description: 'edc:description',
  version: 'edc:version',
  participantId: 'participantId',
  endpointUrl: 'endpointUrl',

  // our asset properties
  keywords: 'edc:keywords',
  originatorOrganization: 'edc:originatorOrganization',
  language: 'edc:language',
  publisher: 'edc:publisher',
  standardLicense: 'edc:standardLicense',
  endpointDocumentation: 'edc:endpointDocumentation',

  // mds specific asset properties
  dataCategory: 'http://w3id.org/mds#dataCategory',
  dataSubcategory: 'http://w3id.org/mds#dataSubcategory',
  dataModel: 'http://w3id.org/mds#dataModel', // guessed
  geoReferenceMethod: 'http://w3id.org/mds#geoReferenceMethod', // guessed
  transportMode: 'http://w3id.org/mds#transportMode',
  
  //policy
  policy: 'odrl:hasPolicy'
};
